@use "sass:color"; @use "sass:list"; @use "sass:math"; $icon-font-path: '~@nbcuniversal-paint/react-ui-kit-css--diva/fonts/'; @import "base.default-settings"; @import "diva.default-settings"; @import "base.settings"; @import "base.mixins"; @import "diva.settings";
.layout {
  @include grid-default(1);
  grid-auto-rows: min-content;
}

.container {
  @include l-constrained();
}

.menus {
  @include grid-default(4, false, $default-spacing, $default-spacing);

  &::before,
  &::after {
    display: none;
  }
}

.wrapper {
  position: relative;
  text-align: left;
}

.menulist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.listitem {
  margin-bottom: $atom-spacing;
}

.menulink {
  color: $colors-utility-white;
  text-decoration: none;

  &:hover {
    color: $colors-utility-white;
    text-decoration: none;
  }
}

.footer {
  background-color: $colors-secondary-delta;
  color: $colors-utility-white;
  padding: $element-spacing 0;
  text-align: center;

  a {
    color: $colors-utility-white;
    text-decoration: none;
  }
}

.footermenu {
  @include grid-default(1, false, $default-spacing, $default-spacing);
  list-style: none;
  margin-bottom: $default-spacing;
  padding: 0;
}

.copyright {
  @include vr-plumbing-set($vr-fonts, small);
  //padding: $element-spacing;
  text-align: center;
}

.legal {
  line-height: 1.5;
}

.logo {
  margin: $default-spacing 0;

  svg {
    fill: $colors-utility-white;
    height: auto;
    max-height: 100px;
    width: 200px;
  }
}

.footerHeading {
  // border-bottom: 1px solid $colors-utility-white;
  // border-top: 1px solid $colors-utility-white;
  // margin-top: $default-spacing;
  // padding: $default-spacing;
  font-family: $font-family-bold;
}
