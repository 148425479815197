@use "sass:color"; @use "sass:list"; @use "sass:math"; $icon-font-path: '~@nbcuniversal-paint/react-ui-kit-css--diva/fonts/'; @import "base.default-settings"; @import "diva.default-settings"; @import "base.settings"; @import "base.mixins"; @import "diva.settings";
.layout {
  position: relative;
}

.loader {
  padding: $element-spacing 0;
}

.wrapper {
  margin-bottom: $element-spacing;
}

.heading {
  margin-left: $default-spacing;
}

.nav {
  border-bottom: 1px solid $colors-greys-delta;
  margin: $default-spacing 0;
  padding: 0 $default-spacing;
  text-align: center;

  a {
    text-decoration: none;
  }
}

.link {
  align-items: center;
  background-color: transparent;
  border-bottom: 5px solid transparent;
  color: $schedule-navitem-color;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-items: center;
  letter-spacing: 1px;
  padding: calc(#{$default-spacing} + 5px) 0 $default-spacing 0;
  text-decoration: none;
  transition: all 150ms ease-in-out;
  width: 100%;

  &:hover {
    border-bottom-color: $schedule-navitem-border-color;
    color: $schedule-navitem-color;
    text-decoration: none;
  }
}

.list {
  @include grid-default(6, false, 0, 0);
}

.list-mobile {
  @include grid-default(1fr 1fr 60px 1fr, 1, 0, 0);
  font-size: 0.8em;
}

.listitem {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
}

.active {
  background-color: $schedule-navitem-active-bg-color;
  border-bottom-color: $schedule-navitem-active-border-color;
  color: $schedule-navitem-active-color;
  font-family: $font-family-bold;

  &:hover {
    color: $schedule-navitem-active-hover-color;
  }
}

.day {
  position: relative;
}

.displaydate {
  font-size: 13px;
  margin: $atom-spacing 0;
}

.calicon {
  fill: $schedule-navitem-color;

  path {
    fill: $schedule-navitem-color;
  }
}

.button {
  background-color: transparent;
  color: $schedule-navitem-active-color;
  cursor: pointer;
  height: 100%;
  width: 100%;

  svg {
    fill: $schedule-navitem-active-color;

    path {
      fill: $schedule-navitem-color;
    }
  }

  &:hover,
  &:active,
  &:focus,
  &:active:focus {
    background-color: transparent;

    svg {
      fill: $schedule-navitem-active-color;

      path {
        fill: $schedule-navitem-color;
      }
    }
  }
}

.closebutton {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;

  button {
    cursor: pointer;
    background: $colors-utility-white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0 0 4px;
  }
}

.overlay {
  align-items: center;
  background-color: rgba($default-body-bg-color, 0.75);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 5;
}
