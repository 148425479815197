@use "sass:color"; @use "sass:list"; @use "sass:math"; $icon-font-path: '~@nbcuniversal-paint/react-ui-kit-css--diva/fonts/'; @import "base.default-settings"; @import "diva.default-settings"; @import "base.settings"; @import "base.mixins"; @import "diva.settings";
.layout {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  margin: auto;
  max-width: 325px;
  padding: $atom-spacing 0;
}

.navItem {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.navLink {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $colors-utility-white;

  &:hover {
    color: $colors-utility-white;
  }
}
