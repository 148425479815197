@use "sass:color"; @use "sass:list"; @use "sass:math"; $icon-font-path: '~@nbcuniversal-paint/react-ui-kit-css--diva/fonts/'; @import "base.default-settings"; @import "diva.default-settings"; @import "base.settings"; @import "base.mixins"; @import "diva.settings";
.logo {
  fill: $text-color;
  padding-bottom: $atom-spacing;
  padding-top: $atom-spacing;

  svg {
    fill: $colors-utility-white;

    * {
      fill: $colors-utility-white;
    }
  }

  g {
    fill: $colors-utility-white;

    * {
      fill: $colors-utility-white;
    }
  }
}

.logotext {
  position: absolute;
  text-indent: -9999px;
}
